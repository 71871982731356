.contact_container{
  padding-top: 6vh;
  display: flex;
  justify-content: center;
  background-color: rgb(67, 67, 67);
}
form{
  /* max-width: 900px; */
  width: 85vw;
  margin: 5vh;
  padding: 40px;
  border-radius: 3px;
  display: flex;
  gap: 10vw;
}
.contactMe{
  margin-bottom: 40px;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 18px;
}
label{
  display: block;
  margin: 18px 0;
  font-size: 18px;
}
input ,textarea
 {
   /* max-width:  50vw; */

  width: 500px;
  padding: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  outline: none;
  color: black;
  border: none;
  resize: none;
  
}
#textarea{
  padding-bottom: 10px;
}
#submit {
  width: auto;
  margin:0px 4px;
  padding:10px 20px;
  align-items: center;
  background-color: rgb(204,1,0);
  color: rgb(255, 255, 255);
  cursor: pointer;
  border-radius: 2px;
  border: none;
  font-family:Roboto, sans-serif;
  font-weight:500;
  font-size:14px;
  letter-spacing:0.5px;
  text-transform:uppercase;
  }
  #submit:hover {
    color: black;
    /* border-width: 0px; */
  }

.message_text{
    font-size: 18px;
}
.socials{
  display: flex;
  padding: 80px 20px;
  flex-direction: column;
  justify-content:flex-start;
  align-items: flex-start;
  gap: 50px;

}
.icons{
 height: 50px;
 color: white;
}
.vertical_social{
  display: flex;
flex-direction: column;
gap: 50px;
}
.horizontal_socials{
  display: flex;
  gap: 20px;
  align-items: flex-end;
}
.info{
  display: flex;
  align-items: center;
  gap:20px;
}
.info p{
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 18px;
}
#facebook:hover{
  cursor: pointer;
  color: #2b64d5;
}
#github:hover{
  cursor: pointer;
  color: gray ;
  
}
#linkedin:hover{
  cursor: pointer;
  color: #0e6c98;
}
#insta:hover{
  cursor: pointer;
  color: #892ebe;
}
@media (max-width:1120px){
  .contact_container{
    padding-top: 0%;
  }
  .contact{
    padding-bottom: 10px;
  }
  form{
    margin-bottom: 0%;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    gap: 5%;
    align-items: center;
  }
  #submit {
    margin-bottom: 15px;
  }
  .socials{
    border-top:2px solid rgb(204,1,0);
    margin-top: 20px;
    width: 90vw;
    align-items: center;
    justify-content: center;
    padding: 40px 20px 0px 20px;
  }

}
@media (max-width:766px){
  label{
    font-size: 16px;
  }
  input,textarea
 {
 width: 100%;
 padding: 5px;
 font-size: 16px;
}
#textarea{
  height: 30px;
  padding: 5px;
}
.icons{
  height: 30px;
  color: white;
 }
 .vertical_social{
gap: 15px;
font-size: 10px;
}
.info p{
  font-size: 14px;
}
.contact{
  padding-top: 10px;
  width: 100%;
}
.horizontal_socials{
  /* display: none; */
}
}