.footer_container{
    background:#ffffff;
    display: flex;
    flex-direction: column-reverse;
    gap:10px;
    align-items: center;
    padding: 5px;
    opacity: 90%;
}
.fa_icons{
    height: 20px;
    color: rgb(0, 0, 0);
}
.footer_socials{
    display: flex;
    align-items: center;
    gap: 10px;
}
.copyright p{
    color: rgb(0, 0, 0);
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 12px;
}