@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
li,
a{
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 12px;
}
.nav_container{
    display: flex;
    width: 100%;
    margin-top: -5px;
    justify-content: center;
    align-items: center;
    padding: 0% 4%;
    position: fixed;
    background-color: #ffffff;
}
.nav_container a{
  margin-right: auto;
}
.nav_logo{
  width: 50px;
  height: 50px;
  object-fit: cover;
  /* object-fit: contain;
  border: 2px solid rgb(214,214,214); */
  /* border-radius: 50%; */
}
.items{
    display: inline-block;
    padding: 0px 30px;
}
.items a {
    transition: all 200ms ease-in-out;
    text-transform: uppercase;
    color: rgb(0, 0, 0);
  }
  .items a:hover {
    border-bottom: 2px solid  rgb(204,1,0);
     color: rgb(204,1,0);
  }
  .active{
    border-bottom: 2px solid  rgb(204,1,0);
    color: rgb(204,1,0);
  }
 .btn{
    padding: 9px 25px;
    border: none;
    margin-left: 20px;
    display: none;
  }
  @media screen and (max-width:766px){
    .nav_container{
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      padding: 0px;
  }
    .list{
      flex-direction: column;
      height: auto;
    }
    .items{
        width: 100%;
        text-align: center;
        padding: 20px 100px;
        border-top: 2px solid rgb(155, 153, 153);
    }
    .btn{
        display:grid;
        align-self: end;
        margin: 10px 0;
    }
   .nav_logo{
         display:none;
   }
  }