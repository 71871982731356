@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro&family=Playfair+Display&family=Roboto+Slab&family=Roboto:wght@700&display=swap');
.wrapper{
    background-color: rgb(67, 67, 67);
    padding: 70px 0 50px 0;
}
.wrapper p{
    display: flex;
    justify-content: center;
    font-size: 30px;
    padding: 10px 0 30px 0;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}
.card_container{
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    grid-column-gap: 20px;
    grid-row-gap: 20px; 
}

@media (max-width:960px){
    .card_container{
        grid-template-columns: auto auto;
    }
}

@media (max-width:766px){
    .card_container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .wrapper p{
        font-size: 18px;
    }
}
